import { fromAbsolute, toCalendarDate, toTimeZone, type CalendarDate } from "@internationalized/date";
import { notNull } from "@warrenio/utils/notNull";
import { createContext, useContext, useEffect, type ReactNode } from "react";
import { TextField, type InputProps, type TextFieldProps } from "react-aria-components";
import { useDebounceCallback } from "usehooks-ts";
import { WSelect } from "../components/forms/WSelect.tsx";
import LR from "../components/LeftRight.module.css";
import { INPUT_DEBOUNCE_MS } from "./debounce.ts";
import type { GqFilterValue, GqlFieldConfig } from "./FieldConfig.tsx";
import { removeEmptyFilter } from "./filterUtils.ts";
import { AInput } from "./form/Fields.tsx";
import { ColumnFilterInputType } from "./graphql.gen/graphql.ts";
import { defaultTz, WDatePicker } from "./WDatePicker.tsx";

export interface FilterEditorProps {
    value: GqFilterValue;
    setValue: (value: GqFilterValue | null) => void;
    fieldConfig: GqlFieldConfig<any, any, any>;
}

/** Use a context to pass the filter's value etc. to the editors, to avoid prop drilling and make it easy to pass custom props to the filter components. */
export const FilterContext = createContext<FilterEditorProps | null>(null);

function useFilterContext(): FilterEditorProps {
    return notNull(useContext(FilterContext), "FilterContext");
}

interface TextFilterProps {
    exact?: boolean;
}

export function TextFilter({ exact = false }: TextFilterProps) {
    const { value, setValue } = useFilterContext();
    const setValueDebounce = useDebounceCallback(setValue, INPUT_DEBOUNCE_MS);

    const type = exact ? ColumnFilterInputType.Exact : undefined;

    return (
        <TextField
            autoFocus
            className="w-8em"
            aria-label="Search text"
            defaultValue={value?.value ?? ""}
            onChange={(value) => setValueDebounce(value ? { type, value } : null)}
        >
            <AInput />
        </TextField>
    );
}

/** Debounced number form field component (for usage in filters) */
function FilterNumberField({
    defaultValue,
    onChange,
    children,
    placeholder,
    ...props
}: {
    defaultValue: number | null;
    onChange: (value: number | null) => void;
    children?: ReactNode;
} & Pick<TextFieldProps, "autoFocus"> &
    Pick<InputProps, "placeholder">) {
    const onChangeDebounce = useDebounceCallback(onChange, INPUT_DEBOUNCE_MS);

    return (
        <TextField
            aria-label={placeholder}
            className="HStack gap-1"
            pattern="[0-9]*"
            inputMode="numeric"
            defaultValue={defaultValue != null ? String(defaultValue) : ""}
            onChange={(value) => {
                const num = value.trim() !== "" ? Number(value) : null;
                if (Number.isNaN(num)) {
                    return;
                }
                onChangeDebounce(num);
            }}
            {...props}
        >
            {children}
            <div
                // XXX: Have to use a wrapper div since `Input` styles force `width: 100%`
                className="w-8em"
            >
                <AInput placeholder={placeholder} />
            </div>
        </TextField>
    );
}

export function ExactNumberFilter() {
    const { value, setValue } = useFilterContext();
    return (
        <FilterNumberField
            autoFocus
            aria-label="Number"
            defaultValue={value?.value != null ? Number(value.value) : null}
            onChange={(value) =>
                setValue(value != null ? { type: ColumnFilterInputType.Exact, value: String(value) } : null)
            }
        />
    );
}

/** Convert a {@link CalendarDate} to a GraphQL filter {@link GqFilterValue.value}. */
function dateToFilterValue(date: CalendarDate | null): string | undefined {
    if (date == null) {
        return undefined;
    }
    return String(date.toDate(defaultTz).valueOf() / 1000);
}

/**
 * Convert a GraphQL filter {@link GqFilterValue.value} to a {@link CalendarDate}.
 *
 * @returns `null` when the filter is not defined
 */
function filterValueToDate(value: string | undefined): CalendarDate | null {
    if (value == null) {
        return null;
    }
    const time = fromAbsolute(Number(value) * 1000, "UTC");
    return toCalendarDate(toTimeZone(time, defaultTz));
}

export function UnixDateRangeFilter() {
    const { value, setValue } = useFilterContext();

    const dateValueFrom = filterValueToDate(value?.value);
    const dateValueTo = filterValueToDate(value?.value2);

    useEffect(() => {
        console.debug("dates:", dateValueFrom?.toString(), dateValueTo?.toString());
    }, [dateValueFrom, dateValueTo]);

    function updateValue(update: Partial<GqFilterValue>) {
        const newValue = { ...value, type: ColumnFilterInputType.Range, ...update };
        setValue(removeEmptyFilter(newValue));
    }

    return (
        <>
            <WDatePicker
                autoFocus
                placeholder="Start"
                defaultValue={dateValueFrom}
                onChange={(date) => {
                    updateValue({ value: dateToFilterValue(date) });
                }}
            />
            <ToLabel />
            <WDatePicker
                placeholder="End"
                defaultValue={dateValueTo}
                onChange={(date) => {
                    updateValue({ value2: dateToFilterValue(date) });
                }}
            />
        </>
    );
}

function ToLabel() {
    return <span className="text-muted">&mdash;</span>;
}

export function NumberRangeFilter() {
    const { value, setValue } = useFilterContext();

    const valueFrom = value?.value != null ? Number(value.value) : null;
    const valueTo = value?.value2 != null ? Number(value.value2) : null;

    function updateValue(update: Partial<GqFilterValue>) {
        const newValue = { ...value, type: ColumnFilterInputType.Range, ...update };
        setValue(removeEmptyFilter(newValue));
    }

    return (
        <>
            <FilterNumberField
                autoFocus
                placeholder="Min"
                defaultValue={valueFrom}
                onChange={(value) => {
                    updateValue({ value: value != null ? String(value) : undefined });
                }}
            />
            <ToLabel />
            <FilterNumberField
                placeholder="Max"
                defaultValue={valueTo}
                onChange={(value) => {
                    updateValue({ value2: value != null ? String(value) : undefined });
                }}
            />
        </>
    );
}

interface EnumItem {
    value: string;
    label: ReactNode;
}

const nullKey = "__null__";

export function EnumFilter({ items, type }: { items: EnumItem[]; type?: ColumnFilterInputType }) {
    const { value, setValue } = useFilterContext();

    function updateValue(value: string | null) {
        setValue(value ? { type, value } : null);
    }

    return (
        <WSelect
            autoFocus
            aria-label="Filter value"
            items={[{ value: null, label: <i>No filter</i> }, ...items]}
            getKey={(item) => item.value ?? nullKey}
            valueKey={value?.value ?? nullKey}
            itemClassName={LR.item}
            valueClassName={LR.value}
            onChange={(value) => updateValue(value.value)}
        >
            {(item) => item.label}
        </WSelect>
    );
}

export function EnumComponentFilter<T extends string>({
    values,
    component: Component,
}: {
    values: T[];
    component: React.FC<{ value: T }>;
}) {
    return <EnumFilter items={values.map((value) => ({ value, label: <Component value={value} /> }))} />;
}

export function DeletedFilter() {
    return (
        <EnumFilter
            items={[
                { value: "include_deleted", label: "Include deleted" },
                { value: "exclude_deleted", label: "Exclude deleted" },
            ]}
        />
    );
}

export function BooleanFilter({ trueLabel, falseLabel }: { trueLabel: ReactNode; falseLabel: ReactNode }) {
    return (
        <EnumFilter
            items={[
                { value: "true", label: trueLabel },
                { value: "false", label: falseLabel },
            ]}
        />
    );
}

export function BooleanComponentFilter({ component: Component }: { component: React.FC<{ value: boolean }> }) {
    return <BooleanFilter trueLabel={<Component value={true} />} falseLabel={<Component value={false} />} />;
}

export function EnabledFilter() {
    return <BooleanFilter trueLabel="Enabled" falseLabel="Disabled" />;
}
