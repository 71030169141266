import { useEffect } from "react";
import { showError } from "./errorStream.ts";

export function useShowError<T>(error: unknown, message = "Error") {
    useEffect(() => {
        if (error) {
            showError(`${message}:`, error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);
}
