import { randomUUID } from "@warrenio/utils/ponyfill/randomUUID";
import { useStore } from "jotai/react";
import { useRef, type RefObject } from "react";
import invariant from "tiny-invariant";
import z from "zod";
import { WButton } from "../components/button/WButton.tsx";
import { showError, softInvariant } from "../modules/error/errorStream.ts";
import { mcn, type BaseProps } from "../utils/baseProps.ts";
import { useOnce } from "../utils/react/useOnce.ts";
import { cursorStoreAtom } from "./Pager.store.ts";
import { useZodStateParser, type State } from "./zodState.ts";

/** Variables passed to GraphQL queries (for offset-based pagination) */
export interface PageVariables {
    limit: number;
    page: number;
}

/** Variables passed to GraphQL queries (for cursor-based pagination) */
export interface CursorVariables {
    limit: number;
    cursor: string | null;
}

/** Input to the pager (from GraphQL response) */
export interface PagingResult {
    total: number;
    cursor?: string | null;
}

/** Common parameters for cursor & offset pagers */
export interface PagerParams {
    urlState: State;
}

export interface UsePager {
    variables: PageVariables | CursorVariables;
    goFirst: () => void;
    useVisual: (info: PagingResult | undefined) => PagerVisualProps;
}

export type UsePagerFn = (params: PagerParams) => UsePager;

/** Interface between the hooks and the {@link Pager} component */
interface PagerVisualProps {
    pageSize: number;
    currentPage: number;
    lastPage: number | undefined;
    total: number | undefined;
    goFirst: (() => void) | undefined;
    goPrev: (() => void) | undefined;
    goNext: (() => void) | undefined;
}

//#region Offset-based pager
export interface PagePagerParams extends PagerParams {
    pageSize?: number;
}

const DEFAULT_PAGE_SIZE = 50;

const PageNumberSchema = z.number().int().min(1);

export function usePagePager({ urlState, pageSize = DEFAULT_PAGE_SIZE }: PagePagerParams): UsePager {
    const [currentPage, setCurrentPage] = useZodStateParser(PageNumberSchema, urlState, 1);
    const goFirst = () => {
        setCurrentPage(() => 1);
    };

    return {
        variables: {
            limit: pageSize,
            page: currentPage,
        },
        goFirst,
        useVisual: (info) => {
            const cachedInfo = useLastDefined(info);

            if (info?.cursor) {
                showError("Cursor is not supported by usePagePager, use useCursorPager instead");
            }

            const total = cachedInfo?.total;
            const lastPage = total != null ? Math.ceil(total / pageSize) : undefined;

            const hasPrev = currentPage > 1;
            const hasNext = cachedInfo && currentPage * pageSize < cachedInfo.total;

            return {
                pageSize,
                currentPage,
                lastPage,
                total,

                goFirst: currentPage > 1 ? goFirst : undefined,
                goPrev: hasPrev ? () => setCurrentPage((p) => p - 1) : undefined,
                goNext: hasNext ? () => setCurrentPage((p) => p + 1) : undefined,
            };
        },
    };
}
//#endregion

//#region Cursor-based pager
export interface CursorPagerParams extends PagerParams {
    pageSize?: number;
}

const FIRST_INDEX = -1;

type CursorPagerState = [
    /** NB: This is not the page number, it starts from {@link FIRST_INDEX} */
    index: number,
    cursor: string | null,
    cursorStoreId: string,
];

const CursorPagerSchema = z.tuple([
    z.number().int().min(FIRST_INDEX),
    z.string().nullable(),
    z.string(),
]) satisfies z.ZodType<CursorPagerState>;

export function useCursorPager({ urlState, pageSize = DEFAULT_PAGE_SIZE }: CursorPagerParams): UsePager {
    // Generate a random ID for the cursor store
    const init_cursorStoreId = useOnce(() => randomUUID());
    const [cursorState, setState] = useZodStateParser(CursorPagerSchema, urlState, [
        FIRST_INDEX,
        null,
        init_cursorStoreId,
    ]);
    const [index, cursor, cursorStoreId] = cursorState;

    //#region Hidden cursor storage
    const store = useStore();
    const cursorStore = store.get(cursorStoreAtom);
    let cursors = cursorStore.get(cursorStoreId);
    if (cursors == null) {
        console.debug("Creating new cursor store", cursorStoreId);
        // Initialize the cursor storage
        cursorStore.set(cursorStoreId, (cursors = []));
        // If the URL contained a cursor, add it to the store (can happen if page is reloaded and stored cursors were all lost)
        if (cursor != null) {
            cursors[index] = cursor;
        }
    }

    /** @returns `undefined` if the cursor store has been lost, `null` if it is the first page */
    const getCursor = (index: number): string | null | undefined => {
        if (index === FIRST_INDEX) {
            return null;
        }
        softInvariant(index >= 0 && index < cursors.length, "Invalid cursor index");
        return cursors[index];
    };
    const storeCursor = (index: number, cursor: string) => {
        invariant(index >= 0, "Invalid cursor index");
        console.debug("Adding cursor %o for index %d", cursor, index);
        cursors[index] = cursor;
    };
    //#endregion

    function setIndex(setter: (oldIndex: number) => number) {
        setState(([index, oldCursor, ...rest]) => {
            const newIndex = setter(index);
            const newCursor = getCursor(newIndex);
            if (newCursor === undefined) {
                showError("Cursor store is lost, unable to go to page %d", newIndex);
                return [index, oldCursor, ...rest];
            }
            return [newIndex, newCursor, ...rest];
        });
    }

    const goFirst = () => {
        setIndex(() => FIRST_INDEX);
    };

    return {
        variables: {
            limit: pageSize,
            cursor,
        },
        goFirst,
        useVisual: (info) => {
            const cachedInfo = useLastDefined(info);

            const total = cachedInfo?.total;
            const lastPage = total != null ? Math.ceil(total / pageSize) : undefined;

            const nextIndex = index + 1;
            const nextCursor =
                info?.cursor ??
                // Use cached next cursor if available
                (nextIndex < cursors.length ? getCursor(nextIndex) : null);

            const prevIndex = index !== FIRST_INDEX ? index - 1 : undefined;
            const prevCursor = prevIndex != null ? getCursor(prevIndex) : null;

            return {
                pageSize,
                /** Offset: Page numbers start from 1, indexes start from -1 */
                currentPage: index + 2,
                lastPage,
                total,

                goFirst: index !== FIRST_INDEX ? goFirst : undefined,
                // NB: `goPrev` is disabled when the cursor store is lost (ie. `prevCursor` is `undefined`)
                goPrev:
                    prevIndex != null && prevCursor !== undefined
                        ? () => setState(([_oldIndex, _oldCursor, ...rest]) => [prevIndex, prevCursor, ...rest])
                        : undefined,
                goNext: nextCursor
                    ? () =>
                          setState(([index, _oldCursor, ...rest]) => {
                              const nextIndex = index + 1;
                              storeCursor(nextIndex, nextCursor);
                              return [nextIndex, nextCursor, ...rest] as const;
                          })
                    : undefined,
            };
        },
    };
}
//#endregion

//#region Pager visual component
interface PagerProps extends BaseProps {
    pager: UsePager;
    result: PagingResult | undefined;
    topRef: RefObject<HTMLElement | null>;
}

export function Pager({ pager: { useVisual }, result, topRef, ...props }: PagerProps) {
    const { currentPage, lastPage, goPrev, goNext, goFirst, pageSize, total } = useVisual(result);

    function scrollToTop() {
        topRef.current?.scrollIntoView({ behavior: "instant" });
    }

    const fromNumber = (currentPage - 1) * pageSize + 1;
    const toNumber = currentPage === lastPage ? total : currentPage * pageSize;

    return (
        <div {...mcn("HStack gap-2", props)}>
            <WButton
                action={() => {
                    goFirst!();
                    scrollToTop();
                }}
                isDisabled={!goFirst}
            >
                First
            </WButton>
            <WButton
                action={() => {
                    goPrev!();
                    scrollToTop();
                }}
                isDisabled={!goPrev}
            >
                Prev
            </WButton>
            <span>
                {currentPage} / {lastPage ?? "..."}
            </span>
            <WButton
                action={() => {
                    goNext!();
                    scrollToTop();
                }}
                isDisabled={!goNext}
            >
                Next
            </WButton>

            <div className="ml-auto">
                Showing {fromNumber} to {toNumber}
                {total != null && <> of {total} results</>}
            </div>
        </div>
    );
}
//#endregion

/** Remember the last {@link value} that was not `undefined` */
function useLastDefined<T>(value: T | undefined): T | undefined {
    const lastValue = useRef<T | undefined>(value);
    if (value !== undefined) {
        lastValue.current = value;
        return value;
    }
    return lastValue.current;
}
