import C from "./AdminTable.module.css";

import { deepEqual, useNavigate } from "@tanstack/react-router";
import { emptyToUndefined } from "@warrenio/utils/collections/emptyToUndefined";
import { filterNulls } from "@warrenio/utils/collections/filterNulls";
import { getOwn } from "@warrenio/utils/collections/getOwnProperty";
import { notNull } from "@warrenio/utils/notNull";
import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { useAtomValue } from "jotai/react";
import { Fragment, type ReactNode, type Ref, useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import { isEmpty, keys } from "remeda";
import type { DraftFunction } from "use-immer";
import z from "zod";
import { link } from "../components/Action.tsx";
import { ActionMenu, type ActionMenuItemProps } from "../components/ActionMenu.tsx";
import { WButton } from "../components/button/WButton.tsx";
import { WSearchField } from "../components/forms/WSearchField.tsx";
import { MaskIcon } from "../components/icon/MaskIcon.tsx";
import { ContentLoadingSuspense } from "../components/loading/Loading.tsx";
import { locationSlugsAtom } from "../modules/api/locations.store.ts";
import { ErrorMessage } from "../modules/error/ErrorFallback.tsx";
import { useShowError } from "../modules/error/useShowError.tsx";
import { Resizable, type ResizerSettings } from "../modules/main/sidebar/Sidebar.tsx";
import { cn } from "../utils/classNames.ts";
import { nothing, produce } from "../utils/immer.ts";
import { AdminScrollPane } from "./AdminLayout.tsx";
import { AdminPowerTable, type AdminPowerTableProps } from "./AdminTable.tsx";
import { AdminTitle } from "./AdminTitle.tsx";
import type { GqFieldFilter, GqlFieldConfig, GqlFieldsOf } from "./FieldConfig.tsx";
import { FilterContext } from "./filters.tsx";
import {
    deserializeQuickFilter,
    isEmptyFilter,
    SerializedQuickFilterSchema,
    serializeQuickFilter,
} from "./filterUtils.ts";
import { OrderDirection } from "./graphql.gen/graphql.ts";
import type { GqData } from "./graphql/extractData.tsx";
import { Pager, type PageVariables, type PagingResult, usePagePager, type UsePagerFn } from "./Pager.tsx";
import { XSticky } from "./StickyArea.tsx";
import { useZodSearchParams } from "./useZodParse.tsx";

function toggleOrder(orderDir: OrderDirection | undefined): OrderDirection {
    return orderDir !== OrderDirection.Desc ? OrderDirection.Desc : OrderDirection.Asc;
}

/** Map from field ID to whether it is visible */
type VisibleOverrideState = Record<string, boolean>;

function ColumnVisibilityMenu({
    visibleFieldIds,
    visibleOverride,
    modifyVisibleOverride,
    fields,
}: {
    visibleFieldIds: string[];
    /** Subset of {@link GqlFieldConfig} */
    fields: { id: string; title: ReactNode; hidden?: boolean }[];

    visibleOverride: VisibleOverrideState;
    modifyVisibleOverride: (apply: DraftFunction<VisibleOverrideState>) => void;
}) {
    const hasOverrides = !isEmpty(visibleOverride);
    return (
        <ActionMenu
            header={
                <>
                    <div>Columns</div>
                    <div className="ml-auto">
                        <WButton
                            ariaLabel="Reset to default"
                            isDisabled={!hasOverrides}
                            color="muted"
                            variant="ghost"
                            size="xs"
                            icon="i-lucide:list-restart"
                            action={() =>
                                modifyVisibleOverride(() => {
                                    for (const key of keys(visibleOverride)) {
                                        delete visibleOverride[key];
                                    }
                                })
                            }
                        />
                    </div>
                </>
            }
            items={fields.map(
                (f): ActionMenuItemProps => ({
                    id: f.id,
                    title: f.title,
                    action: () =>
                        modifyVisibleOverride((visibleOverride) => {
                            const base = !f.hidden;
                            const current = getOwn(visibleOverride, f.id) ?? base;
                            const target = !current;
                            // Only write an override if the desired value is different from the default
                            if (base !== target) {
                                visibleOverride[f.id] = target;
                            } else {
                                delete visibleOverride[f.id];
                            }
                        }),
                }),
            )}
            selectedKeys={visibleFieldIds}
            selectionMode="multiple"
        >
            <WButton
                ariaLabel="Toggle column visibility"
                color={hasOverrides ? "primary" : "muted"}
                variant="ghost"
                icon="jp-icon-toggles"
                action={undefined}
            />
        </ActionMenu>
    );
}

const FlexBorder = () => <div className={C.FlexBorder} />;

/**
 * - Key: Arbitrary ID, currently field ID
 * - Value: `null` when filter has not been initialized yet
 */
type QuickFilterState<TOrderFields extends string = string> = GqFieldFilter<TOrderFields>[];

function QuickFilterEditor<TOrderFields extends string>({
    filters,
    topRef,
    fields,
    modifyFilters,
}: {
    fields: GqlFieldsOf<any, TOrderFields>;

    filters: QuickFilterState<TOrderFields>;
    modifyFilters: (apply: (draft: QuickFilterState<TOrderFields>) => void) => void;

    /** Ref to the top of the this element, can be used for scrolling */
    topRef: Ref<HTMLDivElement>;
}) {
    return (
        <div className={cn(isEmpty(filters) && "hidden", C.FilterBorder, "HStack")} ref={topRef}>
            <div className="p-2 text-muted">Filters:</div>
            <FlexBorder />
            {filters.map((filterParams, idx) => {
                const field = filterParams.field;
                // XXX: This does not work if multiple table fields filter on the same GraphQL field
                const fieldConfig = fields.find((f) => f.order === field);
                if (!fieldConfig) {
                    console.warn("Unknown filter field:", filterParams);
                    return null;
                }

                const Render = notNull(fieldConfig.filter);

                return (
                    <Fragment key={field}>
                        <div className="flex flex-row items-center gap-2 py-2 pl-1">
                            {fieldConfig.title}

                            <FilterContext.Provider
                                value={{
                                    value: filterParams,
                                    setValue: (value) =>
                                        modifyFilters((s) => {
                                            s[idx] = { field, ...value };
                                        }),
                                    fieldConfig,
                                }}
                            >
                                <Render />
                            </FilterContext.Provider>
                            <WButton
                                variant="ghost"
                                size="xs"
                                icon="jp-icon-close"
                                action={() =>
                                    modifyFilters((s) => {
                                        s.splice(idx, 1);
                                    })
                                }
                            />
                        </div>
                        <FlexBorder />
                    </Fragment>
                );
            })}
        </div>
    );
}

/** Common variables passed to all GraphQL table queries (used for eg. sorting and filtering) */
interface StandardVariables<TOrderFields extends string> extends PageVariables {
    orderField?: TOrderFields;
    orderDir?: OrderDirection;
    /** Arbitrary free-text query to search for */
    search?: string;
    filters?: GqFieldFilter<TOrderFields>[];
    /** NB: This field is not always used. Apollo just ignores this if it is not specified in the query params. */
    locations: string[];
}

interface TableViewState<TOrderFields extends string = string> {
    orderDir: OrderDirection;
    orderField?: TOrderFields;
    search: string;
    visibleOverride: VisibleOverrideState;
    detailId?: string;
}

const TableUrlParamsSchema = z
    .object({
        orderDir: z.nativeEnum(OrderDirection),
        orderField: z.string().optional(),
        search: z.string(),
        filters: z.array(SerializedQuickFilterSchema),
        visibleOverride: z.record(z.boolean()),
        detailId: z.string().optional(),
        pg: z.unknown().optional().describe("Pager state"),
    })
    .partial()
    .passthrough();

type TableUrlParams = z.infer<typeof TableUrlParamsSchema>;

function useQuickFilterState(
    filters: TableUrlParams["filters"],
    setUrlFilters: (newUrlValue: TableUrlParams["filters"]) => void,
) {
    // Store the state we write into the URL so we can avoid double updates
    // (since we also update the state when the URL changes)
    const currentUrlFilters = useRef<TableUrlParams["filters"]>(undefined);

    const deserializeFilters = () => filters?.map(deserializeQuickFilter) ?? [];

    // Keep quick filter state separately since it might contain "extra" state that we do not want to put in the URL
    const [quickFilters, setQuickFiltersState] = useState<QuickFilterState>(deserializeFilters);

    // Update quick filter state when URL state changes
    useEffect(() => {
        // Skip our own updates
        if (!deepEqual(filters, currentUrlFilters.current)) {
            console.info("Updating quick filters from URL: %o", filters);

            setQuickFiltersState(deserializeFilters());
            currentUrlFilters.current = filters;
        }
    }, [filters]);

    const setQuickFilters = (fn: (draft: QuickFilterState) => void) => {
        const newValue = produce(quickFilters, fn);
        setQuickFiltersState(newValue);

        // Drop any "half-complete" filters
        const newUrlValue = emptyToUndefined(filterNulls(newValue.map(serializeQuickFilter)));

        // Update URL with new state
        setUrlFilters(newUrlValue);
        currentUrlFilters.current = newUrlValue;
    };

    return [quickFilters, setQuickFilters] as const;
}

const defaultState: TableViewState = {
    orderDir: OrderDirection.Desc,
    orderField: undefined,
    search: "",
    visibleOverride: {},
};

export function GraphqlTable<TItem, TOrderFields extends string>({
    title,
    fields,
    useQuery,
    defaults,
    getId,
    renderDetailToolbar,
    renderDetail,
    usePager = usePagePager,
    ...props
}: {
    fields: GqlFieldsOf<TItem, TOrderFields>;
    defaults?: Partial<TableViewState<TOrderFields>>;

    useQuery: (vars: StandardVariables<TOrderFields>) => GqData<{ items?: TItem[] | null; paging?: PagingResult }>;
    usePager?: UsePagerFn;

    title: string;
    renderDetail?: (item: TItem) => ReactNode;
    renderDetailToolbar?: (item: TItem) => ReactNode;
} & Omit<AdminPowerTableProps<TItem>, "renderTitle" | "items" | "fields" | "topRef" | "sticky">) {
    //#region Hooks

    const topRef = useRef<HTMLTableSectionElement>(null);
    const filtersTopRef = useRef<HTMLDivElement>(null);

    //#region View state

    const navigate = useNavigate();
    const urlState: TableUrlParams | undefined = useZodSearchParams(TableUrlParamsSchema);

    const [quickFilters, setQuickFilters] = useQuickFilterState(urlState?.filters, (value) => {
        setUrlState((draft) => {
            draft.filters = value;
        });
    });

    // NB: Use `string` as the state's `TOrderFields` parameter since otherwise Immer has problems with type errors
    // (drafts are invariant so it's impossible to use `TOrderFields` directly since it occurs in both contravariant and covariant positions below)

    const s: TableViewState = { ...defaultState, ...defaults, ...urlState };
    const isStateEdited = urlState && !isEmpty(urlState);

    const setUrlState = (modify: DraftFunction<TableUrlParams>) => {
        const newValue = produce(urlState, modify);

        // Update URL with new state
        discardPromise(
            navigate({
                // Need a type cast since we do not use TanStack Router strongly typed routes
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                search: newValue != null ? (newValue as any) : ({} as any),
                replace: true,
            }),
        );
    };

    const { detailId } = s;

    //#endregion

    const pager = usePager({
        urlState: [
            urlState?.pg,
            (action) => {
                setUrlState((draft) => {
                    draft.pg = action(draft.pg);
                });
            },
        ],
    });

    const setSearchString = (search: string) => {
        // Go to first page when search changes
        pager.goFirst();
        setUrlState((draft) => {
            draft.search = search;
        });
    };

    const locations = useAtomValue(locationSlugsAtom);

    const query = useQuery({
        ...pager.variables,
        ...(s.orderField ? { orderField: s.orderField, orderDir: s.orderDir } : undefined),
        locations,
        search: s.search || undefined,
        filters: quickFilters.filter((f) => !isEmptyFilter(f)),
        // Need a cast here due to the `TOrderFields` issue above
    } as StandardVariables<TOrderFields>);
    const { data, error, loading } = query;
    useShowError(error, "Error in Graphql table query");

    //#region Detail item

    const detailItemRef = useRef<TItem | undefined>(undefined);

    const detailItemFromResults = data?.items?.find((item) => getId(item) === detailId);
    if (detailItemFromResults) {
        detailItemRef.current = detailItemFromResults;
    } else {
        // If we can not find the detail item right now, keep the previous one selected
    }
    const detailItem = detailId != null ? detailItemRef.current : undefined;

    const openDetail = (item: TItem): void => {
        setUrlState((s) => {
            s.detailId = getId(item);
        });

        // Automatically close the side menu when opening the detail view
        /* if (!hasClosedMenu.current) {
            setMenuCompact(true);
            hasClosedMenu.current = true;
        } */
    };

    const closeDetail = () => {
        setUrlState((s) => {
            delete s.detailId;
        });
        detailItemRef.current = undefined;
    };

    //#endregion Detail item

    //#endregion Hooks

    const visibleFields = fields.filter((f) => getOwn(s.visibleOverride, f.id) ?? !f.hidden);

    const menu = (
        <XSticky>
            <AdminTitle title={title}>
                <WSearchField width="flex-grow ml-auto max-w-20em" value={s.search} onChange={setSearchString} />
                <WButton
                    ariaLabel="Reset"
                    variant="ghost"
                    isDisabled={!isStateEdited}
                    icon="i-lucide:circle-slash-2"
                    action={() => setUrlState(() => nothing)}
                />
                <ColumnVisibilityMenu
                    fields={fields}
                    visibleFieldIds={visibleFields.map((f) => f.id)}
                    visibleOverride={s.visibleOverride}
                    modifyVisibleOverride={(fn) =>
                        setUrlState((s) => {
                            s.visibleOverride ??= {};
                            fn(s.visibleOverride);
                            if (isEmpty(s.visibleOverride)) {
                                delete s.visibleOverride;
                            }
                        })
                    }
                />
            </AdminTitle>

            <QuickFilterEditor
                topRef={filtersTopRef}
                fields={fields}
                filters={quickFilters}
                modifyFilters={setQuickFilters}
            />
        </XSticky>
    );

    const table = (
        <AdminPowerTable
            sticky
            topRef={topRef}
            getId={getId}
            fields={visibleFields}
            items={loading ? undefined : error ? [] : data?.items}
            renderTitle={({ order, title, filter }) => {
                if (!order) {
                    return title;
                }

                const isOrdering = s.orderField === order;
                const isFiltered = !!quickFilters.find((f) => f.field === order);

                const clickQuickFilter = filter
                    ? () => {
                          // NB: We must fully render the filters element before we scroll to it, otherwise the final scroll position will be wrong (since the height of the page changes during scroll)
                          // https://julesblom.com/writing/flushsync
                          flushSync(() =>
                              setQuickFilters((s) => {
                                  const filterIdx = s.findIndex((f) => f.field === order);
                                  if (filterIdx !== -1) {
                                      // Remove the filter
                                      s.splice(filterIdx, 1);
                                  } else {
                                      s.push({ field: order });
                                  }
                              }),
                          );
                          filtersTopRef.current?.scrollIntoView({ block: "nearest", behavior: "smooth" });
                      }
                    : undefined;

                const clickOrder = () =>
                    setUrlState((s) => {
                        s.orderField = order;
                        if (s.orderField === order) {
                            s.orderDir = toggleOrder(s.orderDir);
                        }
                    });

                return (
                    <div className="cursor-pointer HStack gap-1" onClick={clickOrder}>
                        {isOrdering && (
                            <MaskIcon
                                className={`color-muted ${s.orderDir === OrderDirection.Asc ? "jp-icon-caretup" : "jp-icon-caretdown"} size-0.75rem`}
                            />
                        )}
                        {title}
                        {clickQuickFilter && (
                            <WButton
                                variant="ghost"
                                size="xs"
                                color={isFiltered ? "primary" : "muted"}
                                icon="jp-icon-filter-list"
                                action={clickQuickFilter}
                            />
                        )}
                    </div>
                );
            }}
            onClickRow={renderDetail ? openDetail : undefined}
            rowLink={
                renderDetail
                    ? // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                      (item) => link({ to: ".", search: { ...urlState, detailId: getId(item) } } as any)
                    : undefined
            }
            {...props}
        />
    );

    const footer = (
        <XSticky>
            {error && (
                <div className="bg-error-light p-2 b b-b-solid b-gray-2">
                    <div>Error:</div>
                    <ErrorMessage error={error} />
                </div>
            )}
            <Pager className="p-2" pager={pager} topRef={topRef} result={data?.paging} />
        </XSticky>
    );

    return (
        <div className="flex flex-row size-full">
            <AdminScrollPane>
                {menu}
                {table}
                {footer}
            </AdminScrollPane>
            {detailItem != null && renderDetail && (
                <Resizable settings={resizerSettings}>
                    <div className="h-full overflow-x-auto overflow-y-scroll contain-strict">
                        <div className={cn(C.Toolbar, "HStack")}>
                            {renderDetailToolbar?.(detailItem)}

                            <div className={C.ToolbarClose}>
                                <WButton
                                    size="bar"
                                    variant="ghost"
                                    icon="jp-icon-close"
                                    ariaLabel="Close"
                                    action={closeDetail}
                                />
                            </div>
                        </div>

                        <ContentLoadingSuspense>{renderDetail(detailItem)}</ContentLoadingSuspense>
                    </div>
                </Resizable>
            )}
        </div>
    );
}

const resizerSettings: ResizerSettings = {
    minWidth: 300,
    maxWidth: Infinity,
    defaultWidth: 460,
    maxScreenRatio: 0.75,
    storageKey: "adminTableDetailsWidth",
    handleSide: "left",
};
