import { useSearch } from "@tanstack/react-router";
import { useMemo } from "react";
import type z from "zod";
import { useShowError } from "../modules/error/useShowError.tsx";

export function useZodParse<T>(schema: z.ZodType<T>, value: unknown, message = "Error parsing schema") {
    const { error, data } = useMemo(() => schema.safeParse(value), [schema, value]);
    useShowError(error, message);
    return data;
}

export function useZodSearchParams<T>(schema: z.ZodType<T>) {
    const rawSearch = useSearch({ strict: false });
    return useZodParse(schema, rawSearch, "Error parsing search params");
}
