import { type SetStateAction, useCallback, useMemo } from "react";
import type { Jsonifiable } from "type-fest";
import type z from "zod";
import { showError } from "../modules/error/errorStream.ts";

export type State<T = unknown> = [state: T, setState: (state: (prev: T) => T) => void];

/** Wraps a {@link State} with a Zod schema validator */
export function useZodStateParser<T extends Jsonifiable>(
    schema: z.ZodType<T>,
    [state, setState]: State,
    fallback: T,
): State<T> {
    const parse = useCallback(
        (state: unknown): T => {
            if (state !== undefined) {
                const parsed = schema.safeParse(state);
                if (parsed.success) {
                    return parsed.data;
                } else {
                    showError("State parse error", parsed.error);
                }
            }

            if (import.meta.env.DEV) {
                // Validate that the fallback matches the schema
                schema.parse(fallback);
            }

            return fallback;
        },
        [schema, fallback],
    );

    const parsed = useMemo(() => parse(state), [state, parse]);

    const setParsed = (action: SetStateAction<T>) => {
        setState((p) => {
            const current = parse(p);
            return typeof action === "function" ? action(current) : action;
        });
    };
    return [parsed, setParsed] as const;
}
